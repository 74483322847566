import React from 'react'
import pricingIntroStyles from './pricing-introduction.module.scss'

const PricingIntro = (props) => {

    return (
        <section className={pricingIntroStyles.pricinginfo}>

            <div className={"container " + pricingIntroStyles.pricinginfo__container}>

                <div className={pricingIntroStyles.pricinginfo__title}>
                    <h2 className="hintro" dangerouslySetInnerHTML={{ __html: props.title }} />
                </div>

                <div className={pricingIntroStyles.pricinginfo__text}>
                    {props.children}
                </div>

            </div>

        </section>
    )
}

export default PricingIntro