import React from 'react'
import blockStyles from './grey-block.module.scss'

const GreyBlock = (props) => {

    return (
        <section className={blockStyles.block}>

            <div className={"container " + blockStyles.block__container}>
                {props.children}
            </div>

        </section>
    )
}

export default GreyBlock