import React from 'react'
import overviewStyles from './cloud-overview.module.scss'

const CloudOverview = (props) => {

    return (
        <section className={overviewStyles.overview}>
            <div className={overviewStyles.overview__hollywood}>

                <div className={`${overviewStyles.overview__hollywood__light} ${overviewStyles.overview__hollywood__light__one}`}></div>
                <div className={`${overviewStyles.overview__hollywood__light} ${overviewStyles.overview__hollywood__light__two}`}></div>

                <div className={"container " + overviewStyles.overview__container}>

                    <div className={overviewStyles.overview__title}>
                        <h2 className="hintro"><span>Cloud</span> Overview</h2>
                    </div>

                    <div className={overviewStyles.overview__text}>
                        <p className={overviewStyles.overview__large}>Introducing Host3’s powerful <span>managed</span> cloud hosting, built on our 12 years of experience to give you the very best of cloud computing at an <span>affordable</span> price.</p>

                        <p>Utilising the cloud allows us to offer you increased performance, reliability, security, and protection over a conventional hosting setup.</p>

                        <p>Faster-than <span>SSD</span> storage and <span>LiteSpeed</span> web servers outperform the most common hosting setups by over <span>12x!</span></p>
                    </div>

                    <div className={overviewStyles.overview__text}>
                        <p>Each account comes with <span>dedicated</span> resources, this ensures your website always has reliability and performance at all times without the risk of being affected by other customers.</p>

                        <p>The <span>cPanel</span> control panel adds a rich feature set and great support for all of the worlds most popular scripts like WordPress</p>

                        <p>Built to be <span>easily scalable</span> with additional resources, high availability, and the wide range of products we provide such as load balancers, CDN's and SQL servers.</p>
                    </div>

                </div>
            </div>

            <div className={overviewStyles.overview__wave}></div>

        </section>
    )
}

export default CloudOverview