import React from 'react'
import informationStyles from './information.module.scss'

const Information = (props) => {

    return (
        <section className={informationStyles.information}>
            <div className={"container " + informationStyles.information__container}>
                {props.children}
            </div>
        </section>
    )
}

export default Information