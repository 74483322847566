import React from 'react'
import packagesStyles from './packages.module.scss'

const Packages = (props) => {

    return (
        <section className={packagesStyles.packages}>

            <div className={packagesStyles.packages__hollywood}>

                <div className={`${packagesStyles.packages__hollywood__light} ${packagesStyles.packages__hollywood__light__one}`}></div>
                <div className={`${packagesStyles.packages__hollywood__light} ${packagesStyles.packages__hollywood__light__two}`}></div>
                
                <div className={"container " + packagesStyles.packages__container}>

                    <div className={packagesStyles.packages__content}>
                        {props.children}
                    </div>
                    
                    <div className={packagesStyles.packages__table}>

                        <div className={packagesStyles.packages__item}>

                            <div className={packagesStyles.packages__item__inner}>
                                <div className={packagesStyles.packages__item__title}>
                                    Standard
                                </div>

                                <div className={packagesStyles.packages__item__price}>
                                    &pound;17<i>.95</i>
                                    <span>per month</span>
                                </div>

                                <div className={packagesStyles.packages__bar}>
                                    <p>10 GB Storage</p>
                                    <i><span></span></i>
                                </div>

                                <div className={packagesStyles.packages__bar}>
                                    <p>100 GB Bandwidth</p>
                                    <i><span></span></i>
                                </div>

                                <ul>
                                    <li><i></i>Daily Backups</li>
                                    <li><i></i>99.95% Server Uptime SLA</li>
                                    {/*<li><a href="#">View All Features</a></li>*/}
                                </ul>

                                <a href="https://account.host3.co.uk/cart.php?a=add&pid=1" className="button button--large button__blue">Select</a>
                            </div>

                        </div>

                        <div className={packagesStyles.packages__item}>

                            <div className={packagesStyles.packages__item__inner}>
                                <div className={packagesStyles.packages__item__title}>
                                    High Availability<span>100% Uptime SLA</span>
                                </div>

                                <div className={packagesStyles.packages__item__price}>
                                    &pound;36<i>.95</i>
                                    <span>per month</span>
                                </div>

                                <div className={packagesStyles.packages__bar}>
                                    <p>15 GB Storage</p>
                                    <i><span></span></i>
                                </div>

                                <div className={`${packagesStyles.packages__bar} ${packagesStyles.packages__bar__ha}`}>
                                    <p>250 GB Bandwidth</p>
                                    <i><span></span></i>
                                </div>

                                <ul>
                                    <li><i></i>Multi-cloud Failover</li>
                                    <li><i></i>100% Server Uptime SLA</li>
                                    {/*<li><a href="#">View All Features</a></li>*/}
                                </ul>

                                <a href="https://account.host3.co.uk/cart.php?a=add&pid=4" className="button button--large button__blue">Select</a>
                            </div>
                        
                        </div>

                    </div>

                </div>

            </div>

        </section>
    )
}

export default Packages