import React from 'react'

import Store from '../components/store'
import Head from '../components/head'
import Navigation from '../components/navigation'
import Header from '../components/header'
import Packages from '../components/packages'
import Information from '../components/information'
import PricingIntro from '../components/pricing-introduction'
import CloudOverview from '../components/cloud-overview'
import GreyBlock from '../components/grey-block'
import Reviews from '../components/reviews'
import Logos from '../components/logos'
import Talk from '../components/lets-talk'
import Footer from '../components/footer'
import Cookies from '../components/cookies'

const Plans = () => {
    return (
        <Store>
            <Head title="High Availability cPanel Hosting" />
            <Navigation />
            <Header />
            <PricingIntro title="Power of the <span>Cloud</span>">
                <p><strong>Fully managed, </strong>reliable, <strong>affordable,</strong> scalable, and <strong>up to 12x faster</strong> than the competition! Level up your website with our cloud hosting powered by cPanel.</p>
            </PricingIntro>
            <Packages />
            <Information>
                <ul>
                    <li>
                        <i className="icon icon-transfer"></i>
                        <div>
                            <h3>Transfers</h3>
                            <p>Free migrations, setup, and testing to make sure your website is running perfectly</p>
                        </div>
                    </li>
                    <li>
                        <i className="icon icon-lock"></i>
                        <div>
                            <h3>Secure</h3>
                            <p>SSL certificates (HTTPS) and Cloudflare support included keeping your site safe</p>
                        </div>
                    </li>
                    <li>
                        <i className="icon icon-wordpress"></i>
                        <div>
                            <h3>WordPress</h3>
                            <p>We've created the optimal environment for WordPress using LiteSpeed and LSCache</p>
                        </div>
                    </li>
                    <li>
                        <i className="icon icon-check"></i>
                        <div>
                            <h3>Reliable</h3>
                            <p>Always online with a 99.95% Uptime SLA as Standard and 100% for High Availability</p>
                        </div>
                    </li>
                    <li>
                        <i className="icon icon-speed"></i>
                        <div>
                            <h3>Faster</h3>
                            <p>Up to 12x faster than standard hosting with dedicated server resources per account</p>
                        </div>
                    </li>
                    <li>
                        <i className="icon icon-handshake-o"></i>
                        <div>
                            <h3>Managed</h3>
                            <p>Support all the way from code to production and dedicated site-level monitoring</p>
                        </div>
                    </li>
                </ul>
            </Information>
            <CloudOverview/>
            <GreyBlock>
                <h2>High Availability</h2>

                <p>Our high availability package adds a 100% uptime SLA with failover servers ready to go at a moment's notice in the event of any disruption to your site.</p>

                <p>Includes all the features of our standard plan like the cPanel control panel but brings all the reliability of high availability.</p>

                <p>Cross-region servers allow us to protect your site and keep it online even in extreme cases like a data centre outage or accidental loss of data.</p>

                <p>Uses Cloudflare's expansive global network of data centers to deliver your content faster to your visitors.</p>

                <p>For additional security, we route your website through multiple network layers of protection against high-level DDoS attacks.</p>

            </GreyBlock>
            <Reviews/>
            <Logos/>
            <Talk/>
            <Footer/>
            <Cookies />
        </Store>
    )
}

export default Plans
